import { ISbStoriesParams, ISbStoryData } from "@storyblok/react";
import { FC } from "react";
import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import getConfig from "next/config";
import { Landing } from "components/landing/Landing";
import { CommonSsrProps, SbLandingContent } from "types/storyblock";
import { storyblokSingleton } from "services/ssr/storyblok.client.singleton";
import { withSsrTranslations } from "services/ssr/translations";
import { Language } from "utils/enums";

type Props = CommonSsrProps & {
    story: ISbStoryData<SbLandingContent> | null;
};

export const getServerSideProps: GetServerSideProps = async (
    context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<Props>> => {
    const { publicRuntimeConfig: runtimeConfig } = getConfig();
    const storyblokClient = storyblokSingleton.getInstance();

    const locale = context.locale as Language;
    const version = (process.env.VERSION as "published" | "draft") || "draft";
    const sbParams: ISbStoriesParams = {
        version,
        language: locale,
    };

    const [
        { data: categories },
        { data: team },
        { data: page },
        { data: header },
        { data: footer },
        translations,
    ] = await Promise.all([
        storyblokClient.get(`cdn/stories/global-data/categories-data`, sbParams),
        storyblokClient.get(`cdn/stories/global-data/team`, sbParams),
        storyblokClient.get(`cdn/stories/home`, sbParams),
        storyblokClient.get("cdn/stories/header", sbParams),
        storyblokClient.get("cdn/stories/footer", sbParams),
        withSsrTranslations(locale, context),
    ]);

    return {
        props: {
            locale,
            translations,
            runtimeConfig,
            story: page.story,
            key: page.story.id,
            header: header.story,
            footer: footer.story,
            categories: categories.story,
            team: team.story,
            commitHash: process.env.VERCEL_GIT_COMMIT_SHA || "",
        },
    };
};

const IndexPage: FC<Props> = ({ story }) => {
    if (!story) {
        return <div>No Data</div>;
    }

    return <Landing content={story.content} />;
};

export default IndexPage;
