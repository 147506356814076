import { FC, useCallback } from "react";
import { getComponent, SbBlokData, StoryblokComponent } from "@storyblok/react";
import { SbLandingContent } from "types/storyblock";

type Props = {
    content: SbLandingContent;
};

export const Landing: FC<Props> = ({ content }) => {
    const renderContent = useCallback((content: SbBlokData) => {
        let result = null;
        switch (content.component) {
            /* case StoryBlockComponentsEnum.Categories:
                result = <Categories key={content._uid} />;
                break; */
            /* case StoryBlockComponentsEnum.Hero:
                result = <Hero key={content._uid} content={content} />;
                break; */
            /* case StoryBlockComponentsEnum.Video:
                result = <Video key={content._uid} />;
                break; */
            /* case StoryBlockComponentsEnum.Features:
                result = <Features key={content._uid} />;
                break; */
            /* case StoryBlockComponentsEnum.Team:
                result = <Team key={content._uid} />;
                break; */
            /* case StoryBlockComponentsEnum.Reviews:
                result = <Reviews key={content._uid} />;
                break; */
            /* case StoryBlockComponentsEnum.News:
                result = <News key={content._uid} />;
                break; */
            /* case StoryBlockComponentsEnum.Contacts:
                result = <Contacts key={content._uid} content={content} />;
                break; */
            default:
                if (!!getComponent(content.component || "")) {
                    result = <StoryblokComponent key={content._uid} blok={content} />;
                }
                break;
        }
        return result;
    }, []);

    if (!content?.body.length) {
        return null;
    }

    return <>{content.body.map(renderContent)}</>;
};
